<template>
  <section class="ministry-management-open" v-if="worker">
    <PersonComponent :data="worker" />
  </section>
</template>

<script>
import PersonComponent from "components/PersonComponent.vue";
import MINISTRY_MANAGEMENT_OPEN_PAGE from "gql/pages/MinistryManagementOpenPage.graphql";

export default {
  name: "MinistryManagementOpen",
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: MINISTRY_MANAGEMENT_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        if (data) {
          store.dispatch("ministry/save", data);
          const item = store.state.ministry.workers_item;
          store.state.ministry.ministry_breadcrumbs_title = `${item.surname} ${item.name} ${item.patronymic}`;
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.ministry.ministry_more_workers = {
      data: [],
    };
    next();
  },
  computed: {
    worker() {
      return this.$store.state.ministry.workers_item;
    },
  },
  metaInfo() {
    const worker = this.$store.state.ministry.workers_item;
    return {
      title: `${worker.surname} ${worker.name} ${worker.patronymic}`,
    };
  },
  components: { PersonComponent },
};
</script>
